import { NavLink } from "react-router-dom";
import DrtSearch from "./DrtSearch";
import { Modal, Button } from "react-bootstrap";
import React, { useContext, useState } from "react";
import { DrtContext } from "../../../context/DrtContext";
const Menus = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const {
    lang,
  } = useContext(DrtContext);

  return (
    <>
      <div className="nav-wraper">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <nav className="navbar navbar-expand-lg navbar-light custom-nav  w-100">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                      <NavLink to="/">
                      {lang === "en"
                      ? `Home`
                      : `होम`}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/aboutus">About Us</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/casedetail">Case Details </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/causelist">Cause List </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/order">Orders</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/caseTransfer">Case Transfer</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/listOfcpio">List of CPIOs </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/allnotice">Notices</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/contactus"> Contact Us</NavLink>
                    </li>
                    {/* <li className="nav-item">
                      <a
                        href="https://drt.etribunals.gov.in/edrt/login.php"
                        target={"_blank"}
                      >
                        e-Filing
                      </a>
                    </li> */}

                    <li className="nav-item">
                      <NavLink to="/rti">
                      RTI
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <a type="button" onClick={() => setIsOpenModal(true)}>
                        {" "}
                        Login
                      </a>
                    </li>
                  </ul>
                  <DrtSearch />
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* // modal for login */}
      <Modal
        show={isOpenModal}
        className="login-modal"
        onHide={() => setIsOpenModal(false)}
      >
        <Modal.Header>
          <h3>LOGIN</h3>

          <Button
            type="button"
            className="close"
            onClick={() => setIsOpenModal(false)}
          >
            &times;
          </Button>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center loginbtn-sec">
              <ul>
                <li className="login-btn nobdr">
                  {/* <NavLink className="btn common-btn" to={"login"}> */}
                  <a
                    className="btn common-btn"
                    href={`${process.env.REACT_APP_REGISTRY_LOGIN}`}
                    // target="_blank"
                  >
                    <span>
                      <img
                        src="images/spacer.png"
                        width="1"
                        height="1"
                        className="common external"
                        alt="Registry Login"
                      />
                    </span>{" "}
                    Registry Login
                    <span className="arrow-icon">
                      <i className="fas fa-angle-right"></i>
                    </span>
                  </a>
                </li>
                <li className="login-btn nobdr ml-2">
                  <a
                    className="btn common-btn"
                    href="https://drt.etribunals.gov.in/edrt/login.php"
                    // target="_blank"
                  >
                    <span>
                      <img
                        src={`images/spacer.png`}
                        width="1"
                        height="1"
                        className="common internal"
                        alt="e-Filing"
                      />
                    </span>{" "}
                    e-Filing
                    <span className="arrow-icon">
                      <i className="fas fa-angle-right"></i>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Menus;
