import React, { useContext, useState } from "react";
import { Outlet, NavLink } from "react-router-dom";
import ExternalLinkPopup from "../../../services/ExternalLinkPopup";
import { DrtContext } from "../../../context/DrtContext";

const FooterNav = () => {
  const {
    externalLink,
    source,
    setSource,
    setExternalLink,
    setExternalLinkPopupShow,
  } = useContext(DrtContext);

  return (
    <>
      <div className="footer-top-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 copyright-sec">
              <div className="footer-nav-sec">
                <ul>
                  <li>
                    {" "}
                    <NavLink to="/termsCondition">
                      Terms & Conditions
                    </NavLink>{" "}
                  </li>
                  <li>
                    <NavLink to="/privacy">Privacy Policy</NavLink>
                  </li>
                  <li>
                    <NavLink to="/copyright">Copyright Policy</NavLink>
                  </li>
                  <li>
                    <NavLink to="/hyperpolicy">Hyperlinking Policy</NavLink>
                  </li>
                  <li>
                    <NavLink to="/disclaimer">Disclaimer</NavLink>
                  </li>
                  {/* <li><NavLink   to="/accessability" >Accessibility Statement</NavLink></li> */}
                  <li>
                    {" "}
                    <NavLink to="/help">Help</NavLink>
                  </li>
                  <li>
                    {" "}
                    <NavLink to="/faqs"> FAQs </NavLink>{" "}
                  </li>
                  <li>
                    {" "}
                    <NavLink to="/web-information-manager">
                      {" "}
                      Web Information Manager{" "}
                    </NavLink>{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 visitor-sec">
              <div className="text-right social-media">
                <a
                  className="fb external-link"
                  onClick={() => {
                    setExternalLinkPopupShow(true);
                    setExternalLink("https://www.facebook.com/DFSGOI/");
                    setSource("FooterLinks");
                  }}
                >
                  <i className="fab fa-facebook-square">
                    <span className="sr-only">no text</span>
                  </i>
                </a>
                <a
                  className="twit external-link"
                  target={"_blank"}
                  onClick={() => {
                    setExternalLinkPopupShow(true);
                    setExternalLink("https://twitter.com/dfs_india");
                    setSource("FooterLinks");
                  }}
                >
                  <i className="fab fa-twitter">
                    <span className="sr-only">no text</span>
                  </i>
                </a>
                <a
                  className="youtube external-link"
                  target={"_blank"}
                  onClick={() => {
                    setExternalLinkPopupShow(true);
                    setExternalLink(
                      "https://www.youtube.com/c/DepartmentOfFinancialServices"
                    );
                    setSource("FooterLinks");
                  }}
                >
                  <i className="fab fa-youtube">
                    <span className="sr-only">no text</span>
                  </i>
                </a>
                <a
                  className="instagram external-link"
                  target={"_blank"}
                  onClick={() => {
                    setExternalLinkPopupShow(true);
                    setExternalLink("https://www.instagram.com/dfs_india");
                    setSource("FooterLinks");
                  }}
                >
                  <i className="fab fa-instagram">
                    <span className="sr-only">no text</span>
                  </i>
                </a>
                <a
                  className="koo external-link"
                  target={"_blank"}
                  onClick={() => {
                    setExternalLinkPopupShow(true);
                    setExternalLink("https://www.kooapp.com/profile/dfsindia");
                    setSource("FooterLinks");
                  }}
                >
                  <img
                    src={"images/koo.png"}
                    width="30px"
                    height="30px"
                    alt="koo"
                  />
                </a>
              </div>
            </div>

            {externalLink !== "" && source === "FooterLinks" ? (
              <ExternalLinkPopup />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
export default FooterNav;
