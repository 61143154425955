import React, { useState, useEffect, useContext, useRef } from "react";
import BredCrumb from "./BredCrumb";
import InternalBaseBanner from "./InternalBaseBanner";
import axios from "axios";
import { DrtContext } from "../../context/DrtContext";
import { useTitle } from "../../services/PageTitle";
import Captcha from "react-numeric-captcha";
import { useFormik } from "formik";
import { apiStagingOutdoorUrl } from "../../constants";
import { axiosBaseURLExternal } from "../../httpCommon";

const RTI = () => {
  useTitle("RTI");
  const [loading, setLoading] = useState(false);
  const { EncryptDecrypt } = useContext(DrtContext);

  const [captchaStatus, setCaptchaStatus] = useState(false);
  const capt = useRef();

  const [drtLocations, setDrtLocations] = useState([]);
  const refFromDate = useRef("");
  const refToDate = useRef("");
  const [caseDetail, setCaseDetail] = useState([]);
  const [error, setError] = useState(false);

  const fetchRtiDetail = async (url, data) => {
    try {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${apiStagingOutdoorUrl}/${url}`,
        data,
        config
      );
      console.log("Server response:", response.data);
      setCaseDetail(response.data);
    } catch (error) {
      console.error(
        "Error response:",
        error.response ? error.response.data : error.message
      );
      setCaseDetail([]);
      setError(true);
    }
    setLoading(false);
  };

  const initialValues = {
    schemeNameDratDrtId: "",
    fromDate: "",
    toDate: "",
  };
  const fetchDrtLocation = async () => {
    const res = await axiosBaseURLExternal.post("getDrtDratScheamName");
    const sortedLocation = res.data.sort((a, b) =>
      a.SchemaName > b.SchemaName ? 1 : b.SchemaName > a.SchemaName ? -1 : 0
    );
    setDrtLocations(sortedLocation);
  };
  useEffect(() => {
    fetchDrtLocation();
  }, []);

  const validate = (values) => {
    let errors = {};
    if (!values.schemeNameDratDrtId) {
      errors.schemeNameDratDrtId = "Required";
    }

    if (!values.fromDate) {
      errors.fromDate = "Required";
    }
    if (!values.toDate) {
      errors.toDate = "Required";
    }

    return errors;
  };

  const onSubmit = (values, { resetForm }) => {
    if (!captchaStatus) {
      alert("Please enter a valid captcha.");
      return;
    }

    const caseDetailData = {
      schemeNameDrtId: values.schemeNameDratDrtId,
      fromDate: refFromDate.current,
      toDate: refToDate.current,
    };

    fetchRtiDetail("getRti", caseDetailData);
    setCaptchaStatus(false);
    capt.current.refresh();
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  const locationAndDate = (value, name) => {
    if (name === "fromDate") {
      refFromDate.current = value;
    }
    if (name === "toDate") {
      refToDate.current = value;
    }
    console.log(name, value);
  };

  if (loading) {
    return <h6 className="loader">Loading......</h6>;
  }

  return (
    <>
      <InternalBaseBanner title={"RTI"} />
      <BredCrumb title={"RTI"} />
      <div className="common-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="about-content">
                <h6>RTI</h6>

                <form onSubmit={formik.handleSubmit} className="w-100">
                  <div className="form-row">
                    {/* Field 1 */}
                    <div className="form-group col-md-4">
                      <label className="mandatory" htmlFor="staticEmail">
                        {" "}
                        DRT
                      </label>

                      <select
                        className="form-control"
                        placeholder=""
                        type="text"
                        id="schemeNameDratDrtId"
                        name="schemeNameDratDrtId"
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        value={formik.values.schemeNameDratDrtId}
                        onBlur={formik.handleBlur}
                      >
                        <option> Select DRT Location</option>
                        {drtLocations &&
                          drtLocations
                            .filter(
                              (item) => parseInt(item.schemeNameDrtId) < 100
                            )
                            .map((item, index) => (
                              <option
                                value={`${item.schemeNameDrtId}`}
                                key={index}
                              >
                                {item.SchemaName}
                              </option>
                            ))}
                      </select>
                      {formik.touched.schemeNameDratDrtId &&
                      formik.errors.schemeNameDratDrtId ? (
                        <div className="error">
                          {formik.errors.schemeNameDratDrtId}
                        </div>
                      ) : null}
                    </div>

                    {/* Field 2 */}
                    <div className="form-group col-md-4">
                      <label className="mandatory" htmlFor="inputEmail4">
                        From Date
                      </label>
                      <input
                        className="form-control"
                        placeholder=""
                        type="date"
                        id="fromDate"
                        name="fromDate"
                        onChange={(e) => {
                          formik.handleChange(e);
                          locationAndDate(e.target.value, e.target.name);
                        }}
                        value={formik.values.fromDate}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                      {formik.touched.fromDate && formik.errors.fromDate ? (
                        <div className="error">{formik.errors.fromDate}</div>
                      ) : null}
                    </div>

                    {/* Field 3 */}
                    <div className="form-group col-md-4">
                      <label className="mandatory" htmlFor="inputEmail4">
                        To Date
                      </label>
                      <input
                        className="form-control"
                        placeholder=""
                        type="date"
                        id="toDate"
                        name="toDate"
                        onChange={(e) => {
                          formik.handleChange(e);
                          locationAndDate(e.target.value, e.target.name);
                        }}
                        value={formik.values.toDate}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.toDate && formik.errors.toDate ? (
                        <div className="error">{formik.errors.toDate}</div>
                      ) : null}
                    </div>

                    {/* Field Captcha */}
                    <div className="form-group col-md-4 main-menu-captcha capcha-sec">
                      <label>Captcha</label>
                      <div className="col-sm-12 px-0 d-flex">
                        <Captcha
                          onChange={(status) => {
                            setCaptchaStatus(status);
                          }}
                          ref={capt}
                          required
                        />
                        <br />
                        <button
                          type="submit"
                          disabled={!captchaStatus}
                          className="btn btn-reload"
                        >
                          <i className="fas fa-search">
                            <span className="sr-only">no text</span>
                          </i>
                        </button>
                      </div>
                      {!captchaStatus ? (
                        <div className="captcha-text">Enter valid captcha</div>
                      ) : null}
                    </div>
                  </div>
                </form>

                {caseDetail.length === 0 ? null : (
                  <div className="table-responsive mt-5">
                    <table className="table table-striped common-table bluetable">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Subject</th>
                          <th>Document</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {caseDetail.length > 0 ? (
                          caseDetail.map((item, index) => (
                            <tr key={item.id}>
                              <td>{index + 1}</td>
                              <td>{item.subject}</td>
                              <td>
                                <a
                                  href={item.dailyOrderPdf}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className="fas fa-file"></i> View PDF
                                </a>
                              </td>
                              <td>
                                {new Date(item.entry_date).toLocaleDateString()}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="4">
                              Select Above Location and date for RTI
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RTI;
