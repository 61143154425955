import React, { useContext, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { DrtContext } from "../../../context/DrtContext";
import { imageURL } from "../../../constants";
const TopHeader = () => {
  // const [lang, setLang] = useState("en");
  //   const [text, setText] = useState("");
  //   const [showTools, setShowTools] = useState(true);

  // const onChange = (e) => {
  //   setLang(e.target.value);
  //   // console.log(lang);
  // };
  const [togglelang, setToggle] = useState(false);

  const {
    handleSizeDecrease,
    handleSizeIncrease,
    handleSizeOriginal,
    normalTheme,
    blackTheme,
    handleChange,
    handlelangChange,
    handlelangChangedefault,
    externalLink,
    source,
    setSource,
    setExternalLink,
    setExternalLinkPopupShow,
    lang,
  } = useContext(DrtContext);

  const skipToMain = () => {
    window.scrollTo({
      top: 500,
      behavior: "smooth",
    });
  };
  // console.log(lang)

  // google translate
  useEffect(() => {
    var flags = document.getElementsByClassName("flag_link-hi");
    var flagsen = document.getElementsByClassName("flag_link-en");

    Array.prototype.forEach.call(flags, function (e) {
      e.addEventListener("click", function () {
        // dispatch(setLanguageType("hid"));
        // console.log("hindi clicked");
        var lang = e.getAttribute("data-lang");
        var languageSelect = document.querySelector("select.goog-te-combo");
        languageSelect.value = lang;
        languageSelect.dispatchEvent(new Event("change"));
      });
    });

    Array.prototype.forEach.call(flagsen, function (e) {
      e.addEventListener("click", function () {
        var lang = e.getAttribute("data-lang");
        var languageSelect = document.querySelector("select.goog-te-combo");
        languageSelect.value = lang;
        languageSelect.dispatchEvent(new Event("change"));
        // dispatch(setLanguageType("eng"));
        // console.log("english clicked");
      });
    });
  }, []);

  const handleEnClick = () => {
    setToggle(!togglelang);
    handlelangChangedefault();
  };
  const handleHiClick = () => {
    setToggle(!togglelang);
    handlelangChange();
  };

  return (
    <>
      {/* <div className="top-h">
        <div className="container container-fluid-2">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 ">
              <div className="gov-tsection">
                <img
                  className="gov-logo"
                  src="https://drt.gov.in/images/emblem-dark.png"
                  alt="LOGO"
                />

                <div className="gov-text">
                  भारत सरकार <span>Government of India</span>
                </div>
                <div className="gov-text-m">
                  वित्त मत्रांलय वित्तीय सेवाएं विभाग
                  <span>
                    Ministry of Finance Department of Financial Services
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-6 pt-1">
              <div className="text-right social-media">
                <a
                  className="fb external-link"
                  // target={"_blank"}
                  onClick={() => {
                    setExternalLinkPopupShow(true);
                    setExternalLink("https://www.facebook.com/DFSGOI/");
                    setSource("FooterLinks");
                  }}
                  // href="https://www.facebook.com/DFSGOI/"
                >
                  <i className="fab fa-facebook-square">
                    <span className="sr-only">no text</span>
                  </i>
                </a>
                <a
                  className="twit external-link"
                  target={"_blank"}
                  onClick={() => {
                    setExternalLinkPopupShow(true);
                    setExternalLink("https://twitter.com/dfs_india");
                    setSource("FooterLinks");
                  }}
                  // href="https://twitter.com/dfs_india"
                >
                  <i className="fab fa-twitter">
                    <span className="sr-only">no text</span>
                  </i>
                </a>
                <a
                  className="youtube external-link"
                  target={"_blank"}
                  onClick={() => {
                    setExternalLinkPopupShow(true);
                    setExternalLink(
                      "https://www.youtube.com/c/DepartmentOfFinancialServices"
                    );
                    setSource("FooterLinks");
                  }}
                  // href="https://www.youtube.com/c/DepartmentOfFinancialServices"
                >
                  <i className="fab fa-youtube">
                    <span className="sr-only">no text</span>
                  </i>
                </a>
                <a
                  className="instagram external-link"
                  target={"_blank"}
                  onClick={() => {
                    setExternalLinkPopupShow(true);
                    setExternalLink("https://www.instagram.com/dfs_india");
                    setSource("FooterLinks");
                  }}
                  // href="https://www.instagram.com/dfs_india"
                >
                  <i className="fab fa-instagram">
                    <span className="sr-only">no text</span>
                  </i>
                </a>
                <a
                  className="koo external-link"
                  target={"_blank"}
                  onClick={() => {
                    setExternalLinkPopupShow(true);
                    setExternalLink("https://www.kooapp.com/profile/dfsindia");
                    setSource("FooterLinks");
                  }}
                  // href="https://www.kooapp.com/profile/dfsindia"
                >
                  <img
                    src={"images/koo.png"}
                    width="30px"
                    height="30px"
                    alt="koo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="top-header">
        <div className="container container-fluid-2">
          <div className="row align-items-center ">
            <div className="col-12 col-sm-12 col-md-12 col-lg-3 left-nav">
              <ul>
                <li className="nobdr">
                  {/* <Link to="/feedback">Feedback</Link> */}
                  <a href="https://drt.etribunals.gov.in/edrt/help_center.php">
                    Feedback
                  </a>
                </li>
                <li className="nobdr">
                  <Link to="/sitemap">Sitemap</Link>
                </li>
                <li className="nobdr">
                  <a
                    href="https://drt.gov.in/front/dratcauselist_deskboard.php"
                    target="_blank"
                  >
                    Display Board
                  </a>
                </li>
                {/* <li className="nobdr">
                  <a href="https://drt.etribunals.gov.in/" target="_blank">
                    Old Website Link
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-9 top-right-nav">
              <ul>
                <li>
                  <a
                    className="hide skiptomain"
                    // href="https://drt.etribunals.gov.in/front/eSK%20SOP%20Final%20Version%2022-06-2023.pdf"
                    href={`${imageURL}/efiling_notice.pdf`}
                    // href={`/images/efiling_notice.pdf`}
                    target="_blank"
                  >
                    E-Sewakendra SOP
                  </a>
                </li>
                <li>
                  <a
                    href="https://drt.etribunals.gov.in/edrt/help_center.php"
                    className="help-desk-top"
                  >
                    Help Desk
                  </a>
                </li>
                <li>
                  <a
                    href="https://drt.etribunals.gov.in/edrt/help_center.php?gid=2"
                    className="help-desk-top"
                  >
                    Help Desk for Female
                  </a>
                </li>
                {/* <li>
                  <a onClick={skipToMain} className="hide skiptomain">
                    {lang === "en"
                      ? `Skip to Main Content`
                      : `मुख्य विषयवस्तु में जाएं`}
                  </a>
                </li> */}
                <li>
                  <Link to="/screenreader" className="hide">
                    {lang === "en"
                      ? `Screen Reader Access`
                      : `स्क्रीन रीडर एक्सेस`}
                  </Link>
                </li>
                <li className="text-size">
                  {" "}
                  <a className="pt-1" href="#" onClick={handleSizeIncrease}>
                    A+
                  </a>
                  <a className="pt-1 " href="#" onClick={handleSizeOriginal}>
                    A
                  </a>
                  <a className="pt-1" href="#" onClick={handleSizeDecrease}>
                    A -
                  </a>
                  <a href="#" className="whitebg p-1" onClick={normalTheme}>
                    A
                  </a>
                  <a href="#" className={`blackbg p-1`} onClick={blackTheme}>
                    A
                  </a>
                </li>
                <li className="nobdr lang pr-2">
                  {/* <select onChange={(e) => handleChange(e)}>
                    <option value="en">English</option>
                    <option value="hi">Hindi</option>
                  </select> */}
                  {/* <a href="http://91.203.135.173/DRT_application/public/login" target="_blank"><i className="fas fa-user-lock"></i>&nbsp;Login</a> */}
                  <a
                    href={`${process.env.REACT_APP_INTERNAL_LOGIN_URL}`}
                    target="_blank"
                  >
                    <i className="fas fa-user-lock"></i>&nbsp;Login
                  </a>
                  {/* <a href="http://164.100.211.141/home" target="_blank"><i className="fas fa-user-lock"></i>&nbsp;Login</a> */}
                </li>
                <li className="border-right-0 p-0">
                  <span
                    className="google-translate-wrap"
                    id="google_translate_element"
                  ></span>
                </li>
                <li className="lang-custom">
                  <span onClick={() => setToggle(!togglelang)}>
                    Select&nbsp;Language&nbsp;
                    <i className="fas fa-caret-down"></i>
                  </span>
                  <div className={togglelang ? "flag flag-language" : "flag"}>
                    {/* {togglelang ? ( */}
                    <span
                      className="flag_link-en en"
                      onClick={handleEnClick}
                      data-lang="en"
                    >
                      English
                    </span>
                    {/* ) : ( */}
                    <span
                      className="flag_link-hi hi"
                      onClick={handleHiClick}
                      data-lang="hi"
                    >
                      हिंदी
                    </span>
                    {/* )} */}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TopHeader;
